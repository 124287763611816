import { graphql, Link } from "gatsby";
import React from "react";

import Layout from "../../containers/Layout";
import Navigation from "../../components/Blog/Navigation";
import Section from "../../containers/Section";
import BlogList from "../../components/Blog/BlogList";
import useMedia from "use-media";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const BlogCategory = (props: any) => {
  const category = props?.data?.articles?.edges;
  const [showMobileNav, setShowMobileNav] = React.useState<boolean>(false);
  const isMobile = useMedia({ maxWidth: "768px" });

  const {t} = useTranslation();

  React.useEffect(() => {
    if (isMobile) {
      setShowMobileNav(false);
    }
  }, [isMobile]);

  const navigation = [
    {
      link: "/blog",
      text: "All Articles"
    },
    {
      link: "/blog/category/news/",
      text: "News"
    },
    {
      link: "/blog/category/retail%20media/",
      text: "Retail Media"
    },
    {
      link: "/blog/category/marketplaces/",
      text: "Marketplaces"
    },
    {
      link: "/blog/category/advertising/",
      text: "Advertising"
    },
    {
      link: "/blog/category/customer%20stories/",
      text: "Customer Stories"
    }
  ];

  /** Mobile Navigation */
  const mobileNav = (
    <ul className="font-semibold py-13 col-span-8 rounded-lg">
      {navigation.map((item, i) => {
        return (
          <li className="flex mx-1 col-span-8">
            <Link className="py-4 px-8 w-full" key={i} to={item.link}>
              {t(item.text)}
            </Link>
          </li>
        );
      })}
    </ul>
  );
  return (
    <Layout>
      <Section className="grid grid-cols-8 lg:gap-4 pt-60 lg:pt-48">
        <div className="col-span-8 lg:col-span-2 lg:pr-5 lg:block">
          <Navigation
            header={t("Insights & growth rocket fuel")}
            description={t("Expert advice, insider news and industry insights to help you crush your advertising goals.")}
          />
        </div>
        <div className="bg-white col-span-8 sticky top-20 z-10 md:hidden">
          {isMobile && (
            <button
              className="w-full lg:hidden col-span-8 text-2xl font-semibold py-7 focus:outline-none"
              onClick={() => setShowMobileNav(!showMobileNav)}
            >
              {t("Filter")}
            </button>
          )}
          {showMobileNav && mobileNav}
        </div>
        <div className="col-span-8 lg:col-span-6">
          <BlogList publications={category} title={category.name} />
        </div>
      </Section>
    </Layout>
  );
};

export default BlogCategory;

export const query = graphql`
  query CategoryByName($name: String!, $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          language
          data
          ns
        }
      }
    }
    articles: allContentfulPublication(
      sort: { order: DESC, fields: createdAt }
      filter: { categories: { elemMatch: { name: { eq: $name } } }, node_locale: {eq: $language}, slug: {ne: null} }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          timeToRead
          bodyPreview
          body {
            raw
          }
          categories {
            name
          }
          authors {
            name
          }
          bannerImage {
            fluid(quality: 90) {
              src
            }
          }
        }
      }
    }
  }
`;
